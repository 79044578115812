import client from "./requestclient";

const agents = {
  

  joinsmartagent: async body => {
    return await client({
      URL_PATH: "joinsmartagent",
      method: "POST",
      body: body
    });
  },
  
};

export default agents;
